// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'online',
    back: true
  }, {
    prop: 'navigation_button'
  }, {
    prop: 'navigation_type'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'rank'
  }, {
    prop: 'url'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
